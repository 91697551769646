<template>
  <div>
    <error-content :code="$t('pageislost')" desc="The page you`re looking for is not here." :src="src"/>
  </div>
</template>

<script>
import error404 from '@/assets/images/errorpage/error-404.gif'
import errorContent from './error-content.vue'
export default {
  name: 'error_404',
  components: {
    errorContent
  },
  data () {
    return {
      src: error404
    }
  }
}
</script>
